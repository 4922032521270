import React from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, respondTo, breakpoints, css, mixins } from '@styles';
import Image from 'gatsby-image';
import { VsTerminalsComparisonProps } from '@content/types/vsTerminals';
import ButtonLink from '@components/common/ButtonLink';
import * as utils from '@utils';

import thumbUp from '@assets/svg/thumb-up.svg';
import thumbDown from '@assets/svg/thumb-down.svg';

const StepWrapper = styled.div`
  padding-top: 70px;
  margin-top: 0;
  position: relative;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-top: 50px;
    `
  )}

  &:first-of-type {
    margin-top: 20px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1px;
    height: 48px;
    background-color: ${colors.green.dark};
  }

  h2 {
    ${respondFrom(
      breakpoints.lg,
      css`
        text-align: center;
      `
    )}

    ${respondTo(
      breakpoints.lg,
      css`
        font-size: 30px;
      `
    )}
  }
`;

const StepRow = styled.div`
  display: flex;
  align-items: center;
  flex-flow: column;
  margin-top: 50px;

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-flow: row;
      align-items: flex-start;
    `
  )}
`;

const StepImagesWrapper = styled.div`
  width: 100%;
  position: relative;
  height: fit-content;
  order: 1;

  ${respondFrom(
    breakpoints.lg,
    css`
      width: 50%;

      &.side-left {
        order: 2;
      }

      &.side-right {
        order: 1;
      }
    `
  )}
`;

const StepContentWrapper = styled.div`
  width: 100%;
  order: 2;
  margin-top: 60px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-top: 0;
      width: 50%;

      &.side-left {
        order: 1;
      }

      &.side-right {
        order: 2;
      }
    `
  )}

  > div {
    width: 100%;

    ${respondTo(
      breakpoints.lg,
      css`
        margin: 30px 0;
      `
    )}

    ${respondFrom(
      breakpoints.lg,
      css`
        max-width: 350px;
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
      `
    )}

    ${respondFrom(
      breakpoints.xl,
      css`
        max-width: 390px;
        min-height: 250px;
      `
    )}

    ${respondFrom(
      breakpoints.xxl,
      css`
        max-width: 430px;
      `
    )}

    > div {
      position: relative;
      padding-left: 60px;

      > a {
        margin-top: 20px;
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  ${mixins.listDefault};
`;

const StepContentTitle = styled.span`
  display: block;
  font-weight: 700;
  margin-bottom: 10px;
`;

const StepImageTop = styled.div`
  ${respondTo(
    breakpoints.xl,
    css`
      padding-bottom: 100px;

      .gatsby-image-wrapper {
        height: 300px !important;
        width: 450px !important;
        margin-left: auto;
      }
    `
  )}

  ${respondTo(
    breakpoints.lg,
    css`
      padding-bottom: 0;

      .gatsby-image-wrapper {
        height: 60vw !important;
        width: 100% !important;
        margin: auto;
        max-width: 315px;
        max-height: 208px;
        transform: translateX(-40px);
      }

      &.side-left {
        .gatsby-image-wrapper {
          transform: translateX(40px);
        }
      }
    `
  )}
`;

const StepImageBottom = styled.div`
  position: absolute;
  bottom: -100px;
  left: -175px;

  .gatsby-image-wrapper {
    border: 5px solid ${colors.white};
  }

  &.side-right {
    left: auto;
    right: -175px;
  }

  ${respondTo(
    breakpoints.xl,
    css`
      bottom: 0;
      right: 340px;

      &.side-right {
        right: -115px;
      }

      .gatsby-image-wrapper {
        height: 150px !important;
        width: 208px !important;
        margin-left: auto;
      }
    `
  )}

  ${respondTo(
    breakpoints.lg,
    css`
      width: 100%;
      bottom: -60px;
      right: 0;
      left: -120px;

      &.side-right {
        right: -120px;
      }

      .gatsby-image-wrapper {
        height: 35vw !important;
        width: 100% !important;
        margin: auto;
        max-width: 160px;
        max-height: 115px;
      }
    `
  )}
`;

const StepContentPlus = styled.div`
  ${respondFrom(
    breakpoints.lg,
    css`
      margin-right: 85px;
      margin-left: auto;

      &.side-right {
        margin-left: 85px;
        margin-right: auto;
      }
    `
  )}
`;

const StepContentMinus = styled.div`
  ${respondFrom(
    breakpoints.lg,
    css`
      margin-left: 0;
      margin-right: auto;

      &.side-right {
        margin-right: 0;
        margin-left: auto;
      }
    `
  )}
`;

interface VsTerminalsComparisonStepProps extends VsTerminalsComparisonProps {
  side: 'left' | 'right';
  className: string;
  index: number;
  langcode: string;
  urls: Array<string>;
}

export const VsTerminalsComparisonStep = ({
  title,
  side,
  contentPlus,
  contentMinus,
  image1,
  image2,
  button,
  className,
  index,
  label1,
  label2,
  image1Alt,
  image2Alt,
  langcode,
  urls,
}: VsTerminalsComparisonStepProps) => {
  return (
    <StepWrapper className={className} data-index={index + 1}>
      {title && title !== '' && utils.SafeHtml(title)}
      <StepRow>
        <StepImagesWrapper className={`side-${side}`}>
          <StepImageTop className={`side-${side}`}>
            <Image {...image1} alt={image1Alt} />
          </StepImageTop>
          <StepImageBottom className={`side-${side}`}>
            <Image {...image2} alt={image2Alt} />
          </StepImageBottom>
        </StepImagesWrapper>

        <StepContentWrapper className={`side-${side}`}>
          <StepContentPlus className={`side-${side}`}>
            <div>
              <img src={thumbUp} alt="" />
              <StepContentTitle>{label1}</StepContentTitle>
              {utils.SafeHtml(contentPlus)}
              {button.href && button.href !== '' && button.title && button.title !== '' && (
                <ButtonLink
                  to={utils.langLink(langcode, button.href)}
                  color={button.onlyText ? 'text' : 'primary'}
                  icon="arrow-right"
                >
                  {button.title}
                </ButtonLink>
              )}
            </div>
          </StepContentPlus>
          <StepContentMinus className={`side-${side}`}>
            <div>
              <img src={thumbDown} alt="" />
              <StepContentTitle>{label2}</StepContentTitle>
              {utils.SafeHtml(contentMinus)}
            </div>
          </StepContentMinus>
        </StepContentWrapper>
      </StepRow>
    </StepWrapper>
  );
};
