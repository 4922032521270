import React, { Component } from 'react';
import { VsTerminalsComparisonStep } from '@components/kasaVsKasoterminal/VsTerminalsComparisonStep';
import { VsTerminalsComparisonScore } from '@components/kasaVsKasoterminal/VsTerminalsComparisonScore';
import { VsTerminalsComparisonProps } from '@content/types/vsTerminals';

interface VsTerminalsComparisonComponentProps {
  comparison: VsTerminalsComparisonProps[];
  onScoreChange: Function;
  scoreSum: number;
  scoreSettings: Array<string>;
  langcode: string;
  urls: Array<string>;
}

export class VsTerminalsComparison extends Component<VsTerminalsComparisonComponentProps> {
  state = {
    stickyScore: true,
  };

  componentDidMount() {
    document.addEventListener('scroll', this.scrollHandler);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollHandler);
  }

  scrollHandler = () => {
    const allSteps = Array.from(document.querySelectorAll('.comaprison-step'));
    const height = window.innerHeight;

    allSteps.forEach(step => {
      const stepIndex = Number(step.getAttribute('data-index'));

      if (step.getBoundingClientRect().top * 4 < height && this.props.scoreSum < stepIndex) {
        this.props.onScoreChange(step.getAttribute('data-index'));
      }
    });
    this.checkIfScoreOverFooter();
  };

  checkIfScoreOverFooter = () => {
    const mainComponent = document.querySelector('.tl-wrapper');
    const heightToFooter = mainComponent ? mainComponent.getBoundingClientRect().bottom : null;
    const windowHeight = window.innerHeight;
    let sticky = true;

    if (heightToFooter && heightToFooter <= windowHeight) {
      sticky = false;
    }

    this.setState({ stickyScore: sticky });
  };

  render() {
    const { comparison, scoreSettings } = this.props;
    return (
      <div>
        {comparison.map((step, index) => (
          <VsTerminalsComparisonStep
            key={index}
            side={index % 2 === 0 ? 'left' : 'right'}
            {...step}
            className="comaprison-step"
            index={index}
            langcode={this.props.langcode}
            urls={this.props.urls}
          />
        ))}

        <VsTerminalsComparisonScore
          sum={this.props.scoreSum}
          sticky={this.state.stickyScore}
          scoreSettings={scoreSettings}
        />
      </div>
    );
  }
}
