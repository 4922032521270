import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import actions from '@store/actions';
import VsTerminalsComponent, { VsTerminalsProps } from '@components/kasaVsKasoterminal';
import { RootState } from '@store/types';

const mapStateToProps = (state: RootState) => ({
  configurator: state.configurator,
  content: state.content,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  configuratorActions: bindActionCreators(actions.configurator, dispatch),
  contentActions: bindActionCreators(actions.content, dispatch),
});

interface DVsTerminalsPropsProps extends VsTerminalsProps {}
const DVsTerminals: React.FC<DVsTerminalsPropsProps> = (props: DVsTerminalsPropsProps) => (
  <VsTerminalsComponent {...props} />
);

const VsTerminalsConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DVsTerminals);

export default VsTerminalsConnected;
