import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions, mixins, respondFrom, breakpoints, css } from '@styles';

const ScoreWrapper = styled.div<ScoreWrapperProps>`
  position: ${props => (props.sticky ? 'fixed' : 'absolute')};
  bottom: ${props => (props.sticky ? '0' : '-45px')};
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  justify-content: center;
  overflow: hidden;
  transform: ${props => (props.isVisible ? 'translateY(0)' : 'translateY(100%)')};
  ${mixins.transitionDefault};
  z-index: 15;
`;

const ScoreInner = styled.div`
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    background-color: ${colors.text.default};
    color: ${colors.white};
    padding: 20px 0;
    min-width: 120px;
    font-size: ${dimensions.fontSize.regular}px;
    text-align: center;
    height: 60px;

    ${respondFrom(
      breakpoints.md,
      css`
        min-width: 180px;
        font-size: ${dimensions.fontSize.large}px;
        padding: 30px 0;
        height: 83px;
      `
    )}

    &:nth-last-of-type(2) {
      background-color: ${colors.black};
      color: ${colors.green.dark};
      min-width: 0;
      font-size: 30px;
      font-weight: 700;
      padding: 11px 0;
      position: relative;
      min-width: 80px;
      text-align: center;

      ${respondFrom(
        breakpoints.md,
        css`
          min-width: 110px;
          font-size: 40px;
          padding: 16px 0;
        `
      )}
    }
  }
`;

interface VsTerminalsComparisonScoreProps {
  sum: number;
  sticky: boolean;
  scoreSettings: Array<string>;
}

interface ScoreWrapperProps {
  isVisible: boolean;
  sticky: boolean;
}

export const VsTerminalsComparisonScore = ({
  sum,
  sticky,
  scoreSettings,
}: VsTerminalsComparisonScoreProps) => (
  <ScoreWrapper isVisible={sum > 0} sticky={sticky}>
    <ScoreInner>
      <span>{scoreSettings[26]}</span>
      <span>{sum}:0</span>
      <span>{scoreSettings[27]}</span>
    </ScoreInner>
  </ScoreWrapper>
);
