import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useSprings, animated, config } from 'react-spring';

import { colors, dimensions, respondFrom, respondTo, breakpoints, css } from '@styles';
import VsTerminalsCostChart from '@components/kasaVsKasoterminal/VsTerminalsCostChart';
import { VsTerminalsPageCostChart } from '@content/types/vsTerminals';

import IconTick from '@assets/svg/feature-incl-icon.svg';
import IconClose from '@assets/svg/feature-notincl-icon.svg';
import AnimationTriggerWaypoint, { WaypointDependent } from '@animations/AnimationTriggerWaypoint';
import * as utils from '@utils';

const VsTerminalsCostsWrapper = styled.div`
  position: relative;
  padding-top: 70px;

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-top: 50px;
    `
  )}

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 1px;
    height: 48px;
    background-color: ${colors.green.dark};
  }

  h2 {
    margin-bottom: 10px;

    ${respondFrom(
      breakpoints.lg,
      css`
        text-align: center;
      `
    )}

    ${respondTo(
      breakpoints.lg,
      css`
        font-size: 30px;
      `
    )}
  }
`;

const VsTerminalsCostsData = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row;
    `
  )}
`;

const VsTerminalsCostsDataItem = styled.div`
  background-color: ${colors.grayNurse};
  padding: 30px 25px;
  margin: 20px 0;

  ${respondFrom(
    breakpoints.md,
    css`
      max-width: 420px;
      margin: 0 15px;
      padding: 40px 50px;
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      margin: 0 65px;
    `
  )}

  > h3 {
    margin: 0;
  }

  > p {
    margin-bottom: 40px;
    min-height: 20px;
  }
`;

const VsTerminalsCostList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    position: relative;
    padding-left: 20px;
    margin: 10px 0;
    font-size: ${dimensions.fontSize.small}px;
  }

  img {
    position: absolute;
    top: 2px;
    left: 0;
  }
`;

const VsTerminalsTitle = styled.div`
  text-align: center;

  p {
    margin-bottom: 10px;

    ${respondFrom(
      breakpoints.md,
      css`
        margin-bottom: 30px;
      `
    )}

    ${respondFrom(
      breakpoints.lg,
      css`
        text-align: center;
        margin-bottom: 60px;
      `
    )}
  }
`;

const AnimatedVsTerminalsCostChart = animated(VsTerminalsCostChart);

interface VsTerminalsCostsProps {
  settings: VsTerminalsPageCostChart;
}

export const VsTerminalsCosts = ({ settings }: VsTerminalsCostsProps) => {
  const amounts = [
    Number(settings.list[3]),
    0,
    Number(settings.list[9]),
    Number(settings.list[11]),
  ];
  const percents = [72, 0, 59, 72];
  const amountsB = [
    Number(settings.list[16]),
    Number(settings.list[19]),
    Number(settings.list[22]),
    Number(settings.list[24]),
  ];
  const percentsB = [62, 73, 91, 100];
  const delay = [1000, 1200, 1400, 1600];

  const [visibility, setVisibility] = useState(false);
  const [vals, valuesSet] = useSprings(amounts.length, i => ({
    from: { amount: 0, percent: 0, amountB: 0, percentB: 0 },
  }));
  valuesSet((i: number) => ({
    amount: visibility ? amounts[i] : 0,
    percent: visibility ? percents[i] : 0,
    amountB: visibility ? amountsB[i] : 0,
    percentB: visibility ? percentsB[i] : 0,
    delay: 1000 + i * 200,
  }));

  return (
    <>
      <AnimationTriggerWaypoint onChange={setVisibility} />
      <VsTerminalsCostsWrapper>
        <VsTerminalsTitle>{utils.SafeHtml(settings.title)}</VsTerminalsTitle>
        <VsTerminalsCostsData>
          <VsTerminalsCostsDataItem>
            <h3>{settings.list[0]}</h3>
            <p>{settings.list[1]}</p>
            <div>
              <AnimatedVsTerminalsCostChart
                title={settings.list[2]}
                text={vals[0].amount.interpolate(x => x.toFixed(0))}
                textAfter={settings.list[4]}
                percent={percents[0]}
                visibility={visibility}
                delay={delay[0]}
                active={true}
              />
              <AnimatedVsTerminalsCostChart
                title={settings.list[5]}
                text={settings.list[6]}
                active={true}
              />
              <AnimatedVsTerminalsCostChart
                title={settings.list[7]}
                textBefore={settings.list[8]}
                text={vals[2].amount.interpolate(x => x.toFixed(0))}
                textAfter={settings.list[4]}
                percent={percents[2]}
                visibility={visibility}
                delay={delay[2]}
                active={true}
              />
              <AnimatedVsTerminalsCostChart
                title={settings.list[10]}
                text={vals[3].amount.interpolate(x => x.toFixed(0))}
                textAfter={settings.list[4]}
                percent={percents[3]}
                visibility={visibility}
                delay={delay[3]}
                active={true}
              />
            </div>
            <VsTerminalsCostList>
              <li>
                <img src={IconTick} alt="" />
                {settings.list[12]}
              </li>
              <li>
                <img src={IconTick} alt="" />
                {settings.list[13]}
              </li>
            </VsTerminalsCostList>
          </VsTerminalsCostsDataItem>

          <VsTerminalsCostsDataItem>
            <h3>{settings.list[14]}</h3>
            <p></p>
            <div>
              <AnimatedVsTerminalsCostChart
                title={settings.list[15]}
                text={vals[0].amountB.interpolate(x => x.toFixed(0))}
                textAfter={settings.list[4]}
                percent={percentsB[0]}
                visibility={visibility}
                delay={delay[0]}
              />
              <AnimatedVsTerminalsCostChart
                title={settings.list[17]}
                textBefore={settings.list[18]}
                text={vals[1].amountB.interpolate(x => x.toFixed(0))}
                textAfter={settings.list[4]}
                percent={percentsB[1]}
                visibility={visibility}
                delay={delay[1]}
              />
              <AnimatedVsTerminalsCostChart
                title={settings.list[20]}
                textBefore={settings.list[21]}
                text={vals[2].amountB.interpolate(x => x.toFixed(0))}
                textAfter={settings.list[4]}
                percent={percentsB[2]}
                visibility={visibility}
                delay={delay[2]}
              />
              <AnimatedVsTerminalsCostChart
                title={settings.list[23]}
                text={vals[3].amountB.interpolate(x => x.toFixed(0))}
                textAfter={settings.list[4]}
                percent={percentsB[3]}
                visibility={visibility}
                delay={delay[3]}
              />
            </div>
            <VsTerminalsCostList>
              <li>
                <img src={IconClose} alt="" />
                {settings.list[25]}
              </li>
            </VsTerminalsCostList>
          </VsTerminalsCostsDataItem>
        </VsTerminalsCostsData>
      </VsTerminalsCostsWrapper>
    </>
  );
};
