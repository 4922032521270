import React from 'react';

import styled from '@emotion/styled';
import { colors, dimensions, mixins, animation } from '@styles';

const ChartWrapper = styled.div`
  margin-bottom: 25px;

  > p {
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 700;
    margin-bottom: 10px;
  }
`;

const Chart = styled.div<ChartProps>`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 40px;
  border-radius: 100px;
  border: 1px solid ${colors.gray};
  display: flex;
  align-items: center;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;

    ${mixins.transitionDefault}
    transform-origin: left center;
    opacity: ${props => (props.visibility === 'true' ? '1' : '0')};
    ${props =>
      props.visibility === 'true' && props.percent
        ? `transform: scaleX(${props.percent * 0.01});`
        : `transform: scaleX(0);`};
    ${props => (props.active ? mixins.gradientGreen : mixins.gradient('#a9a9a9', '#cecece'))};
    transition-delay: ${props => `${props.delay}ms`};
    transition-duration: ${animation.subtle}ms;
  }

  > p {
    position: relative;
    z-index: 1;
    margin: 0;
    font-size: ${dimensions.fontSize.small}px;
    margin-left: 30px;
    color: ${props =>
      props.active && props.percent && props.percent > 30 ? colors.white : colors.text.default};
  }
`;

interface VsTerminalsCostChartProps {
  title: string;
  textBefore?: string;
  text: string | number;
  textAfter?: string;
  percent?: number;
  active?: boolean;
  visibility?: boolean;
  delay?: number;
}

interface ChartProps {
  percent?: number;
  active?: boolean;
  visibility: string;
  delay: number;
}

class VsTerminalsCostChart extends React.Component<VsTerminalsCostChartProps> {
  render() {
    return (
      <ChartWrapper>
        <p>{this.props.title}</p>
        <Chart
          active={this.props.active}
          percent={this.props.percent}
          visibility={`${this.props.visibility}`}
          delay={this.props.delay ? this.props.delay : 0}
        >
          <p>
            {this.props.textBefore || ''}
            {this.props.text}
            {this.props.textAfter || ''}
          </p>
        </Chart>
      </ChartWrapper>
    );
  }
}

export default VsTerminalsCostChart;
