import React from 'react';
import VsTerminalsContainer from '@containers/kasaVsKasoterminal';
import useVsTerminalsQuery from '@graphql/queries/VsTerminalsQuery';
import MetaPage from '@components/common/MetaPage';
import DataLayer from '@components/layout/DataLayer';
import { LocationDependent } from '@content/types/general';
import { PageContext } from '@content/types/pageContext';

interface VsTerminalsPageProps extends LocationDependent {
  pageContext: PageContext;
}

export default (props: VsTerminalsPageProps) => {
  const vsTerminalsData = useVsTerminalsQuery()[props.pageContext.langcode];

  return (
    <>
      <MetaPage title={vsTerminalsData.seo.title} description={vsTerminalsData.seo.description} image={vsTerminalsData.seo.image} />
      <DataLayer location={props.location} />
      <VsTerminalsContainer
        {...vsTerminalsData}
        langcode={props.pageContext.langcode}
        urls={props.pageContext.allUrls}
      />
    </>
  );
};
