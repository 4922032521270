import React from 'react';
import { useSpring, useSprings, animated, config } from 'react-spring';

import { Stylable } from '@content/types/general';
import { WaypointDependent } from '@animations/AnimationTriggerWaypoint';

interface Props extends Stylable, WaypointDependent {}

const strokeDasharrays = [200];
const initialDelay = 1000;
const stepsDelay = 400;

export default (props: Props) => {
  // lines animation
  const [animLines, animLinesSet] = useSprings(strokeDasharrays.length, i => ({
    from: { strokeDashoffset: strokeDasharrays[i] },
    config: config.molasses,
  }));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  animLinesSet((i: number, spring: any) => ({
    strokeDashoffset: props.sectionVisible ? 0 : strokeDasharrays[i],
    delay: initialDelay + i * stepsDelay,
  }));

  // dots animation
  const [animDots, animDotsSet] = useSprings(3, i => ({
    from: { opacity: 0 /*, transform: 'scale(0)'*/ },
    config: config.default,
  }));
  animDotsSet(i => ({
    opacity: props.sectionVisible ? 1 : 0,
    // transform: props.sectionVisible ? 'scale(1)' : 'scale(0)',
    delay: initialDelay + 1000 + i * stepsDelay,
  }));

  // icon animation
  const [animIcon, animIconSet] = useSpring(i => ({
    from: { opacity: 0, transform: 'scale(0) rotate(0deg)' },
    config: config.slow,
  }));
  animIconSet({
    opacity: props.sectionVisible ? 1 : 0,
    transform: props.sectionVisible ? 'scale(0.9) rotate(720deg)' : 'scale(0) rotate(0deg)',
    delay: initialDelay + 1500,
  });

  return (
    <div className={props.className}>
      <svg xmlns="http://www.w3.org/2000/svg" width="285" height="91" viewBox="0 0 285 91">
        <g fill="none" fillRule="evenodd">
          {/* linia */}
          <animated.path
            strokeDasharray={strokeDasharrays[0]}
            strokeDashoffset={animLines[0].strokeDashoffset}
            stroke="#929292"
            // strokeDasharray="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M4.682 19.96h117.352m85.768 0h49.77c12.703 0 23 10.298 23 23v46.288"
          />
          {/* dots */}
          <animated.g style={animDots[0]} fill="#929292" transform="translate(0 15)">
            <circle cx="4.5" cy="4.5" r="4.5" />
            <circle cx="122.5" cy="4.5" r="4.5" />
            <circle cx="207.5" cy="4.5" r="4.5" />
            <circle cx="280.5" cy="71.5" r="4.5" />
          </animated.g>
          {/* klepsydra */}
          <animated.path
            style={{
              transformOrigin: '58% 20%',
              ...animIcon,
            }}
            stroke="#094"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.44"
            d="M170.186 16.785c1.181.24 2.379.477 3.979.192 1.601-.284 2.644-.919 3.671-1.551m-27.914 9.591c3.465 1.585 13.337 2.79 16.049.333M148 24.05l13.426 13.425m23.05-23.05L171.05 1m-10.576 35.522c4.008-3.521 5.906-6.019 5.794-10.523-.061-2.435-1.689-4.472.032-6.194 1.722-1.722 3.76-.094 6.196-.033 4.647.116 7.371-2.135 10.972-6.25M171.954 2.007c-4.116 3.603-6.366 6.326-6.25 10.974.06 2.435 1.689 4.473-.034 6.195-1.721 1.721-3.757.092-6.193.032-4.496-.111-7.062 1.864-10.522 5.795"
          />
        </g>
      </svg>
    </div>
  );
};
