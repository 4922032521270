import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, respondTo, breakpoints, css } from '@styles';
import ButtonLink from '@components/common/ButtonLink';
import Button from '@components/common/Button';
import { VsTerminalsPageBanner } from '@content/types/vsTerminals';
import * as utils from '@utils';

const VsTerminalsBannerWrapper = styled.div`
  position: relative;
  padding-top: 110px;

  ${respondFrom(
    breakpoints.md,
    css`
      padding: 0;
      margin-top: 140px;

      &::before {
        display: none;
      }
    `
  )}

  &::before {
    content: '';
    position: absolute;
    top: 20px;
    left: 0;
    right: 0;
    width: 1px;
    height: 48px;
    background-color: ${colors.green.dark};
    margin: auto;
  }

  h2 {
    ${respondTo(
      breakpoints.md,
      css`
        font-size: 25px;
      `
    )}

    ${respondFrom(
      breakpoints.md,
      css`
        text-align: center;
        max-width: 815px;
        margin: 50px auto;
      `
    )}
  }
`;

const VsTerminalsBannerButtons = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;

  > div,
  a {
    max-width: fit-content;
    margin: 10px 0;
  }

  > div {
    order: 2;

    button {
      background-color: transparent;
    }
  }

  > a {
    order: 1;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row;

      > div,
      a {
        margin: 0 50px;
      }

      > div {
        order: 1;
      }

      > a {
        order: 2;
      }
    `
  )}
`;

interface VsTerminalsBannerProps {
  onScoreChange: Function;
  settings: VsTerminalsPageBanner;
  langcode: string;
  urls: Array<string>;
}

export class VsTerminalsBanner extends Component<VsTerminalsBannerProps> {
  goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    setTimeout(() => {
      this.props.onScoreChange(0);
    }, 1500);
  };

  render() {
    return (
      <VsTerminalsBannerWrapper>
        {utils.SafeHtml(this.props.settings.paragraph)}
        <VsTerminalsBannerButtons>
          <div onClick={this.goToTop}>
            <Button color="text" icon="arrow-right">
              {utils.SafeHtml(this.props.settings.button1)}
            </Button>
          </div>

          <ButtonLink
            to={utils.langLink(this.props.langcode, this.props.urls[4])}
            icon="arrow-right"
          >
            {utils.SafeHtml(this.props.settings.button2)}
          </ButtonLink>
        </VsTerminalsBannerButtons>
      </VsTerminalsBannerWrapper>
    );
  }
}
